import Term from "../@types/services/Term";
import api from "./api";

type TermForm = {
  name: string;
  mandatory: boolean;
  attachment: File;
};

export const fetchTerms = async (): Promise<Term[]> => {
  const response = await api.get<Term[]>(`/terms`, {
    baseURL: "/vsupplierregistration/api/v2",
  });

  return response.data;
};

export const fetchTerm = async (id: string): Promise<Term> => {
  const response = await api.get<Term>(`/terms/${id}`, {
    baseURL: "/vsupplierregistration/api/v2",
  });
  return response.data;
};

export const createTerm = async (data: TermForm): Promise<Term> => {
  const response = await api.post<Term>(`/terms`, data, {
    baseURL: "/vsupplierregistration/api/v2",
  });

  await addTermDocument(response.data.id, data.attachment);
  return response.data;
};

export const addTermDocument = async (
  termId: string,
  attachment: File,
): Promise<void> => {
  const formData = new FormData();
  formData.append("attachment", attachment);

  await api.post<Term>(`/terms/${termId}/add_term_document`, formData, {
    baseURL: "/vsupplierregistration/api/v2",
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
