import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  Paper,
  TextField,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { FC } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../components/atoms/PageTitle";
import { createTerm } from "../../services/terms";

type InputForm = {
  name: string;
  mandatory: boolean;
  attachment: File;
};

const FormTerm: FC = () => {
  const { handleSubmit, control } = useForm<InputForm>();
  const navigate = useNavigate();

  const handleFormSubmit: SubmitHandler<InputForm> = (data) => {
    createTerm(data)
      .then(() => {
        navigate("/vsupplierregistration/terms");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Paper
      sx={{
        marginTop: 2,
        maxWidth: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        padding: "24px",
      }}
    >
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Grid2 container spacing={2}>
          <Grid2 xs={12}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Nome"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid2>
          <Grid2 xs={12}>
            <Controller
              name="mandatory"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} />}
                  label="Obrigatório"
                />
              )}
            />
          </Grid2>
          <Grid2 xs={12}>
            <Controller
              name="attachment"
              control={control}
              render={({ field: { onChange, value, ...field } }) => (
                <>
                  <InputLabel htmlFor="attachment">Anexo</InputLabel>
                  <FormControl fullWidth>
                    <Input
                      {...field}
                      type="file"
                      inputProps={{ accept: ".pdf,.doc,.docx" }}
                      id="attachment"
                      onChange={(e) =>
                        onChange((e.target as HTMLInputElement).files?.[0])
                      }
                    />
                  </FormControl>
                </>
              )}
            />
          </Grid2>
          <Grid2 xs={12}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Criar
            </Button>
          </Grid2>
        </Grid2>
      </form>
    </Paper>
  );
};

const NewTerm: FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        padding: "16px",
        height: "100%",
        width: "100%",
      }}
    >
      <PageTitle title="Novo Termo" />
      <FormTerm />
    </Box>
  );
};

export default NewTerm;
