import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Switch,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import i18next from "i18next";
import { enqueueSnackbar } from "notistack";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Term from "../../../@types/services/Term";
import TermDocument from "../../../@types/services/TermDocument";
import { createAcceptanceTerm } from "../../../services/acceptanceTerms";
import { fetchTerms } from "../../../services/terms";

type AcceptanceTermFormProps = {
  term: Term;
  termDocument: TermDocument;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setTerms: Dispatch<SetStateAction<Term[] | undefined>>;
};

type AcceptanceTermForm = {
  accepted: boolean;
};

const AcceptanceTermForm: FC<AcceptanceTermFormProps> = ({
  term,
  termDocument,
  setLoading,
  setTerms,
}) => {
  const [openConfirm, setOpenConfirm] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<AcceptanceTermForm>();

  const handleFetchTerms = () => {
    setLoading(true);
    fetchTerms().then((data) => {
      setTerms(data);
      setLoading(false);
    });
  };

  const onSubmit: SubmitHandler<AcceptanceTermForm> = (data) => {
    const formData = { ...data, term_document_id: termDocument.id };

    createAcceptanceTerm(formData).then(() => {
      handleFetchTerms();
      enqueueSnackbar("Termo aceito com sucesso", { variant: "success" });
      setOpenConfirm(false);
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid2 container spacing={2}>
        <Grid2 xs={12} sx={{ display: "flex", justifyContent: "flex-start" }}>
          <Controller
            name="accepted"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <>
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      checked={
                        termDocument?.acceptance_term?.accepted || field.value
                      }
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                        setOpenConfirm(true);
                      }}
                      disabled={
                        termDocument?.acceptance_term?.accepted ||
                        field.value ||
                        false
                      }
                    />
                  }
                  label="Aceitar Termo"
                />
                <Dialog
                  open={openConfirm}
                  onClose={() => setOpenConfirm(false)}
                >
                  <DialogTitle>
                    {i18next.t("terms.dialog.title")} {term.name}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {i18next.t("terms.dialog.message", { term: term.name })}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        field.onChange(false);
                        setOpenConfirm(false);
                      }}
                      color="primary"
                    >
                      {i18next.t("terms.dialog.cancel")}
                    </Button>
                    <Button onClick={handleSubmit(onSubmit)} color="primary">
                      {i18next.t("terms.dialog.confirm")}
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
          />
        </Grid2>
      </Grid2>
    </form>
  );
};

export default AcceptanceTermForm;
