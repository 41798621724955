import { Box, Paper } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Term from "../../@types/services/Term";
import PageTitle from "../../components/atoms/PageTitle";
import LoadingCentral from "../../components/molecules/LoadingCentral";
import TermForm from "../../components/molecules/TermForm";
import { fetchTerm } from "../../services/terms";

interface FormTermProps {
  term: Term;
  handleFetchTerms: () => void;
}

type TermDocumentForm = {
  name: string;
  version: string;
  mandatory: boolean;
  active: boolean;
  justification: string;
  attachment: File;
};

const EditTerm: FC = () => {
  const { termId } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [term, setTerm] = useState<Term>();

  const handleFetchTerm = async (id: string) => {
    setLoading(true);
    fetchTerm(id).then((data) => {
      setTerm(data);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (termId) {
      handleFetchTerm(termId);
    }
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        padding: "16px",
        width: "100%",
      }}
    >
      <PageTitle title="Atualização de termo" />
      <Paper
        sx={{
          marginTop: 2,
          display: "flex",
          maxWidth: "100%",
          height: "100%",
          flexDirection: "column",
          padding: "16px",
        }}
      >
        {term && !loading ? <TermForm term={term} /> : <LoadingCentral />}
      </Paper>
    </Box>
  );
};

export default EditTerm;
