import { mdiChevronDown } from "@mdi/js";
import Icon from "@mdi/react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  ButtonGroup,
  Divider,
  Paper,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import i18next from "i18next";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Term from "../../@types/services/Term";
import TermDocument from "../../@types/services/TermDocument";
import PageTitle from "../../components/atoms/PageTitle";
import AcceptanceTermForm from "../../components/molecules/AcceptanceTermForm";
import LoadingCentral from "../../components/molecules/LoadingCentral";
import { fetchTermDocument } from "../../services/termDocuments";
import { fetchTerms } from "../../services/terms";
import { getIsVendor } from "../../services/users";

interface ItemTermProps {
  term: Term;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setTerms: Dispatch<SetStateAction<Term[] | undefined>>;
  isVendor: boolean;
}

const ItemTerm: FC<ItemTermProps> = ({
  term,
  setLoading,
  setTerms,
  isVendor,
}) => {
  const [termDocument, setTermDocument] = useState<TermDocument>();

  const handlFetchTermDocument = async () => {
    fetchTermDocument(term.active_term_document.id).then((data) =>
      setTermDocument(data),
    );
  };

  useEffect(() => {
    handlFetchTermDocument();
  }, []);

  if (!termDocument) {
    return <LoadingCentral />;
  }

  return (
    <>
      {isVendor &&
        term.mandatory &&
        !termDocument.acceptance_term?.accepted && (
          <Alert severity="error">
            Você precisa aceitar o termo obrigatório!!
          </Alert>
        )}
      <Accordion key={term.id} sx={{ marginBottom: 2 }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "center",
            "& > *": {
              m: 1,
            },
          }}
        >
          <Grid2
            container
            sx={{
              display: "flex",
              width: "100%",
            }}
          >
            <Grid2 xs={6}>
              <Typography variant="h5" sx={{ margin: 2 }}>
                {term.name}
              </Typography>
            </Grid2>
            <Grid2 xs={6}>
              <Typography variant="h6" sx={{ margin: 2 }}>
                {i18next.t("terms.lastUpdate")}
                {i18next.t("dateTime", {
                  val: Date.parse(String(termDocument.created_at)),
                  interpolation: { escapeValue: false },
                  formatParams: {
                    val: {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                      // hour12: false,
                    },
                  },
                })}
              </Typography>
            </Grid2>
          </Grid2>
          <AccordionSummary
            expandIcon={<Icon path={mdiChevronDown} size={0.8} />}
            aria-controls="panel1-content"
            id="panel1-header"
          />
        </Box>
        <Divider />
        <AccordionDetails>
          <Box sx={{ margin: 1 }}>
            {isVendor ? (
              <AcceptanceTermForm
                term={term}
                termDocument={termDocument}
                setLoading={setLoading}
                setTerms={setTerms}
              />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  margin: 1,
                  padding: 1,
                  width: "100%",
                }}
              >
                <ButtonGroup
                  size="small"
                  aria-label="Small button group"
                  sx={{ "& > *": { mr: 1 } }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    component={Link}
                    to={`/vsupplierregistration/terms/${term.id}/edit`}
                  >
                    {i18next.t("terms.button.editTerm")}
                  </Button>
                </ButtonGroup>
              </Box>
            )}
          </Box>
          <Divider />
          {termDocument && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <iframe
                src={termDocument.attachment.url}
                width="100%"
                height="800px"
                style={{ marginTop: "16px" }}
                title={termDocument.attachment.filename}
              />
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

const Terms: FC = () => {
  const [terms, setTerms] = useState<Term[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [isVendor, setIsVendor] = useState<boolean>();

  const handleFetchTerms = () => {
    fetchTerms().then((data) => {
      setTerms(data);
    });
  };

  const handleIsVendor = () => {
    getIsVendor().then((data) => {
      setIsVendor(data);
    });
  };

  useEffect(() => {
    setLoading(true);
    handleFetchTerms();
    handleIsVendor();

    setLoading(false);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        padding: "16px",
        width: "100%",
      }}
    >
      <PageTitle title="Termos" />

      <Paper
        sx={{
          marginTop: 2,
          display: "flex",
          maxWidth: "100%",
          height: "100%",
          flexDirection: "column",
          padding: "16px",
        }}
      >
        {!isVendor && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              margin: 1,
              padding: 1,
              width: "100%",
            }}
          >
            <ButtonGroup
              size="small"
              aria-label="Small button group"
              sx={{ "& > *": { mr: 1 } }}
            >
              <Button
                color="primary"
                variant="contained"
                component={Link}
                to={`/vsupplierregistration/terms/new`}
              >
                {i18next.t("terms.button.newTerm")}
              </Button>
            </ButtonGroup>
          </Box>
        )}
        {terms && !loading ? (
          terms.map((term) => (
            <ItemTerm
              term={term}
              setTerms={setTerms}
              setLoading={setLoading}
              isVendor={isVendor || false}
            />
          ))
        ) : (
          <LoadingCentral />
        )}
      </Paper>
    </Box>
  );
};

export default Terms;
