import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  TextField,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { enqueueSnackbar } from "notistack";
import { FC, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Term from "../../../@types/services/Term";
import TermDocument from "../../../@types/services/TermDocument";
import {
  createTermDocument,
  fetchTermDocument,
} from "../../../services/termDocuments";
import LoadingCentral from "../LoadingCentral";

type TermFormProps = {
  term: Term;
};

type TermDocumentForm = {
  name: string;
  version: string;
  mandatory: boolean;
  active: boolean;
  justification: string;
  attachment: File;
};

const TermForm: FC<TermFormProps> = ({ term }) => {
  const [termDocument, setTermDocument] = useState<TermDocument>();
  const handlFetchTermDocument = async () => {
    fetchTermDocument(term.active_term_document.id).then((data) =>
      setTermDocument(data),
    );
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<TermDocumentForm>();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<TermDocumentForm> = (data) => {
    const formData = { ...data, term_id: term.id };
    if (data.attachment) {
      createTermDocument(formData, data.attachment).then(() => {
        enqueueSnackbar("Termo atualizado com sucesso", { variant: "success" });
        navigate("/vsupplierregistration/terms");
      });
    } else {
      enqueueSnackbar("É necessário anexar um arquivo", { variant: "error" });
    }
  };

  useEffect(() => {
    handlFetchTermDocument();
  }, []);

  if (!termDocument) {
    return <LoadingCentral />;
  }

  return (
    <>
      <Box sx={{ margin: 1 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid2 container spacing={2}>
            <Grid2 xs={6}>
              <Controller
                name="name"
                control={control}
                defaultValue={term.name}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Nome"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid2>
            <Grid2 xs={6}>
              <Controller
                name="mandatory"
                control={control}
                defaultValue={term.mandatory}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label="Obrigatório"
                  />
                )}
              />
              <Controller
                name="active"
                control={control}
                defaultValue={term.active}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label="Ativo"
                  />
                )}
              />
            </Grid2>
            <Grid2 xs={12}>
              <Controller
                name="justification"
                control={control}
                defaultValue={termDocument.justification}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Justificativa"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid2>
            <Grid2 xs={12}>
              <Controller
                name="attachment"
                control={control}
                render={({ field: { onChange, value, ...field } }) => (
                  <>
                    <InputLabel htmlFor="attachment">Anexo</InputLabel>
                    <FormControl fullWidth>
                      <Input
                        {...field}
                        type="file"
                        inputProps={{ accept: ".pdf,.doc,.docx" }}
                        id="attachment"
                        onChange={(e) =>
                          onChange((e.target as HTMLInputElement).files?.[0])
                        }
                        required
                      />
                    </FormControl>
                  </>
                )}
              />
            </Grid2>
            <Grid2 xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Grid2 xs={2}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Atualizar
                </Button>
              </Grid2>
            </Grid2>
          </Grid2>
        </form>
      </Box>
      <Divider />
      {termDocument && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <iframe
            src={termDocument.attachment.url}
            width="100%"
            height="800px"
            style={{ marginTop: "16px" }}
            title={termDocument.attachment.filename}
          />
        </Box>
      )}
    </>
  );
};

export default TermForm;
