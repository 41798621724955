import AcceptanceTerm from "../@types/services/AcceptanceTerm";
import api from "./api";

interface AcceptanceTermForm {
  accepted: boolean;
  term_document_id: string;
}

export const createAcceptanceTerm = async (
  data: AcceptanceTermForm,
): Promise<AcceptanceTerm> => {
  const response = await api.post<AcceptanceTerm>(`/acceptance_terms`, data, {
    baseURL: "/vsupplierregistration/api/v2",
  });

  return response.data;
};
