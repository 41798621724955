import { Alert, Box, Card, Pagination } from "@mui/material";
import i18next from "i18next";
import {
  FC,
  MouseEvent as ReactMouseEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import ServiceNegotiation from "../../@types/services/ServiceNegotiation";
import { Filter } from "../../components/molecules/Filter";
import ListServiceNegotiations from "../../components/molecules/ListServiceNegotiations";
import {
  ServiceNegotiationFilter,
  fetchServiceNegotiations,
} from "../../services/serviceNegotiations";
import { checkMandatoryTerms } from "../../services/users";

const ServiceNegotiations: FC = () => {
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const { control, handleSubmit, reset } = useForm();
  const [searchParams] = useSearchParams();
  const [serviceNegotiations, setServiceNegotiations] = useState<
    ServiceNegotiation[]
  >([]);
  const [missingTerms, setMissingTerms] = useState(false);

  const handleFetchServiceNegotiations = useCallback(
    async (page: number, filters?: ServiceNegotiationFilter) => {
      setLoading(true);
      let appliedFilters: ServiceNegotiationFilter = {};
      if (filters) {
        appliedFilters = filters;
      }

      if (searchParams.get("tab") === "open") {
        appliedFilters = { ...appliedFilters, status_name: ["Waiting Offer"] };
      } else if (searchParams.get("tab") === "ongoing") {
        appliedFilters = {
          ...appliedFilters,
          status_name: [
            "Waiting Bid",
            "Evaluating Proposals",
            "Issuing Purchase Order",
          ],
        };
      } else if (searchParams.get("tab") === "finished") {
        appliedFilters = {
          ...appliedFilters,
          status_name: ["Lost", "Rejected By Vendor", "Cancelled", "Finished"],
        };
      }

      await fetchServiceNegotiations({
        page,
        itemsPerPage: rowsPerPage,
        filters: appliedFilters,
      }).then((sq) => {
        setServiceNegotiations(() => sq.results || []);
        setTotalRows(() => sq.total_items || 0);
        setLoading(false);
      });
    },
    [rowsPerPage, searchParams],
  );

  const handleChangePage = (
    event: ReactMouseEvent<HTMLButtonElement, MouseEvent>,
    value: number,
  ): void => {
    setCurrentPage(() => value);
  };

  const handleFilterClear = (): void => {
    reset();
    setCurrentPage(() => 1);
    handleFetchServiceNegotiations(currentPage);
  };

  const handleFilterSubmit: SubmitHandler<ServiceNegotiationFilter> = (
    data,
  ): void => {
    setCurrentPage(() => 1);
    handleFetchServiceNegotiations(currentPage, data);
  };

  useEffect(() => {
    handleFetchServiceNegotiations(currentPage);
    checkMandatoryTerms().then((response) => {
      if (response) {
        setMissingTerms(response.missing_terms);
      }
    });
  }, [currentPage, handleFetchServiceNegotiations]);

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 1,
        justifyContent: "center",
        display: "flex",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <form onSubmit={handleSubmit(handleFilterSubmit)}>
          <Filter.Root>
            <Filter.TextField
              control={control}
              type="number"
              label={i18next.t("serviceNegotiation.prNumber")}
              name="pr_number"
            />
            <Filter.Actions>
              <Filter.Action
                text={i18next.t("apply")}
                type="submit"
                disabled={loading}
              />
              <Filter.Action
                text={i18next.t("clean")}
                onClick={handleFilterClear}
                sxClass={{
                  color: "red",
                  "&:hover": { backgroundColor: "rgba(255, 0, 0, 0.1)" },
                }}
              />
            </Filter.Actions>
          </Filter.Root>
        </form>
        {missingTerms ? (
          <Card
            sx={{
              p: 2,
              borderRadius: "10px",
              border: "5px",
              width: "100%",
              height: "100%",
            }}
          >
            <Alert severity="error">
              {i18next.t("serviceNegotiation.missingTermsAlert")}
            </Alert>
          </Card>
        ) : (
          <ListServiceNegotiations
            serviceNegotiations={serviceNegotiations}
            loading={loading}
          />
        )}
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
          <Pagination
            count={Math.ceil(totalRows / rowsPerPage)}
            page={currentPage}
            onChange={handleChangePage}
            color="primary"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ServiceNegotiations;
